import { Icon } from "@sagaware/ui-icons/Icon"
import { cn } from "@sagaware/utils/cn"
import { useSpinDelay } from "spin-delay"

import { forwardRef } from "react"
import { Button, type ButtonProps } from "../ui/button"
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "../ui/tooltip"

export const ButtonWithStatus = forwardRef<
	HTMLButtonElement,
	ButtonProps & {
		status: "pending" | "success" | "error" | "idle"
		message?: string | null
		spinDelay?: Parameters<typeof useSpinDelay>[1]
	}
>(({ message, status, className, children, spinDelay, ...props }, ref) => {
	const delayedPending = useSpinDelay(status === "pending", {
		delay: 400,
		minDuration: 300,
		...spinDelay,
	})
	const companion = {
		pending: delayedPending ? (
			<div
				aria-live="polite"
				className="inline-flex size-6 items-center justify-center"
			>
				<Icon name="Update" className="animate-spin" title="loading" />
				{/* <Icon name="update" className="animate-spin" title="loading" /> */}
			</div>
		) : null,
		success: (
			<div
				aria-live="polite"
				className="inline-flex size-6 items-center justify-center"
			>
				<Icon name="Check" title="success" />
				{/* <Icon name="check" title="success" /> */}
			</div>
		),
		error: (
			<div
				aria-live="polite"
				className="inline-flex size-6 items-center justify-center rounded-full bg-destructive"
			>
				<Icon
					name="Cross1"
					className="text-destructive-foreground"
					title="error"
				/>
				{/* <Icon
          name="cross-1"
          className="text-destructive-foreground"
          title="error"
        /> */}
			</div>
		),
		idle: null,
	}[status]

	return (
		<Button
			ref={ref}
			className={cn("flex justify-center gap-4", className)}
			{...props}
		>
			<div>{children}</div>
			{message ? (
				<TooltipProvider>
					<Tooltip>
						<TooltipTrigger>{companion}</TooltipTrigger>
						<TooltipContent>{message}</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			) : (
				companion
			)}
		</Button>
	)
})
ButtonWithStatus.displayName = "Button"
