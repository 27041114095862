import { useId } from "react"
import { useInputControl } from "@conform-to/react"

import { Checkbox, type CheckboxProps } from "@sagaware/ui-shadcn/ui/checkbox"
import { Input } from "@sagaware/ui-shadcn/ui/input"
import { Label } from "@sagaware/ui-shadcn/ui/label"
import { Switch } from "@sagaware/ui-shadcn/ui/switch"
import { Textarea } from "@sagaware/ui-shadcn/ui/textarea"

export type ListOfErrors = Array<string | null | undefined> | null | undefined

export function ErrorList({
	id,
	errors,
}: {
	errors?: ListOfErrors
	id?: string
}) {
	const errorsToRender = errors?.filter(Boolean)
	if (!errorsToRender?.length) return null
	return (
		<ul id={id} className="flex flex-col gap-1">
			{errorsToRender.map((e) => (
				<li key={e} className="text-sm text-pink-500">
					{e}
				</li>
			))}
		</ul>
	)
}

export function Field({
	labelProps,
	inputProps,
	errors,
	className,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	inputProps: React.InputHTMLAttributes<HTMLInputElement>
	errors?: ListOfErrors
	className?: string
}) {
	const fallbackId = useId()
	const id = inputProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={className}>
			<Label htmlFor={id} {...labelProps} />
			<Input
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				{...inputProps}
				className="mt-1"
			/>
			<div className="min-h-[32px] pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	)
}

export function TextareaField({
	labelProps,
	textareaProps,
	errors,
	className,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	textareaProps: React.TextareaHTMLAttributes<HTMLTextAreaElement>
	errors?: ListOfErrors
	className?: string
}) {
	const fallbackId = useId()
	const id = textareaProps.id ?? textareaProps.name ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={className}>
			<Label htmlFor={id} {...labelProps} />
			<Textarea
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				{...textareaProps}
				className="mt-1"
			/>
			<div className="min-h-[32px] pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	)
}

export function CheckboxField({
	labelProps,
	buttonProps,
	errors,
	className,
}: {
	labelProps: JSX.IntrinsicElements["label"]
	buttonProps: CheckboxProps & {
		name: string
		form: string
		value?: string
	}
	errors?: ListOfErrors
	className?: string
}) {
	const { key, defaultChecked, ...checkboxProps } = buttonProps
	const fallbackId = useId()
	const checkedValue = buttonProps.value ?? "on"
	const input = useInputControl({
		key,
		name: buttonProps.name,
		formId: buttonProps.form,
		initialValue: defaultChecked ? checkedValue : undefined,
	})
	const id = buttonProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined

	return (
		<div className={className}>
			<div className="flex gap-2">
				<Checkbox
					{...checkboxProps}
					id={id}
					aria-invalid={errorId ? true : undefined}
					aria-describedby={errorId}
					checked={input.value === checkedValue}
					onCheckedChange={(state) => {
						input.change(state.valueOf() ? checkedValue : "")
						buttonProps.onCheckedChange?.(state)
					}}
					onFocus={(event) => {
						input.focus()
						buttonProps.onFocus?.(event)
					}}
					onBlur={(event) => {
						input.blur()
						buttonProps.onBlur?.(event)
					}}
					type="button"
				/>
				{/* <label
					htmlFor={id}
					{...labelProps}
					className="self-center text-body-xs text-muted-foreground"
				/> */}
			</div>
			<div className="pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	)
}

export function SwitchField({
	labelProps,
	buttonProps,
	errors,
	className,
}: {
	labelProps: JSX.IntrinsicElements["label"]
	buttonProps: CheckboxProps & {
		name: string
		form: string
		value?: string
	}
	errors?: ListOfErrors
	className?: string
}) {
	const { key, defaultChecked, ...checkboxProps } = buttonProps
	const fallbackId = useId()
	const checkedValue = buttonProps.value ?? "on"
	const input = useInputControl({
		key,
		name: buttonProps.name,
		formId: buttonProps.form,
		initialValue: defaultChecked ? checkedValue : undefined,
	})
	const id = buttonProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined

	return (
		<div className={className}>
			<div className="flex gap-3">
				<Switch
					{...checkboxProps}
					id={id}
					aria-invalid={errorId ? true : undefined}
					aria-describedby={errorId}
					checked={input.value === checkedValue}
					onCheckedChange={(state) => {
						input.change(state.valueOf() ? checkedValue : "")
						buttonProps.onCheckedChange?.(state)
					}}
					onFocus={(event) => {
						input.focus()
						buttonProps.onFocus?.(event)
					}}
					onBlur={(event) => {
						input.blur()
						buttonProps.onBlur?.(event)
					}}
					type="button"
				/>
				{/* <label
					htmlFor={id}
					{...labelProps}
					className="self-center text-body-xs text-muted-foreground"
				/> */}
			</div>
			<div className="pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	)
}

export function SwitchBoxedField({
	title,
	description,

	buttonProps,
	errors,
	className,
}: {
	title: string
	description?: string

	buttonProps: CheckboxProps & {
		name: string
		form: string
		value?: string
	}
	errors?: ListOfErrors
	className?: string
}) {
	const { key, defaultChecked, ...checkboxProps } = buttonProps
	const fallbackId = useId()
	const checkedValue = buttonProps.value ?? "on"
	const input = useInputControl({
		key,
		name: buttonProps.name,
		formId: buttonProps.form,
		initialValue: defaultChecked ? checkedValue : undefined,
	})
	const id = buttonProps.id ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined

	return (
		<div className="flex items-center space-x-4 rounded-md border p-4">
			<div className="flex-1 space-y-1">
				<p className="text-sm font-medium leading-none">{title}</p>
				<p className="text-sm text-muted-foreground">{description}</p>
			</div>
			<div className={className}>
				<div className="flex gap-3">
					<Switch
						{...checkboxProps}
						id={id}
						aria-invalid={errorId ? true : undefined}
						aria-describedby={errorId}
						checked={input.value === checkedValue}
						onCheckedChange={(state) => {
							input.change(state.valueOf() ? checkedValue : "")
							buttonProps.onCheckedChange?.(state)
						}}
						onFocus={(event) => {
							input.focus()
							buttonProps.onFocus?.(event)
						}}
						onBlur={(event) => {
							input.blur()
							buttonProps.onBlur?.(event)
						}}
						type="button"
					/>
				</div>
				{/* <div className="pb-3 pt-1">
          {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
        </div> */}
			</div>
		</div>
	)
}

export function SelectField({
	labelProps,
	selectProps,
	errors,
	className,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
	selectProps: React.SelectHTMLAttributes<HTMLSelectElement>
	errors?: ListOfErrors
	className?: string
}) {
	const fallbackId = useId()
	const id = selectProps.id ?? selectProps.name ?? fallbackId
	const errorId = errors?.length ? `${id}-error` : undefined
	return (
		<div className={className}>
			<Label htmlFor={id} {...labelProps} />
			<select
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				{...selectProps}
			/>
			<div className="min-h-[32px] px-4 pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	)
}

// export function OTPField({
// 	labelProps,
// 	inputProps,
// 	errors,
// 	className,
// }: {
// 	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
// 	inputProps: Partial<OTPInputProps & { render: never }>
// 	errors?: ListOfErrors
// 	className?: string
// }) {
// 	const fallbackId = useId()
// 	const id = inputProps.id ?? fallbackId
// 	const errorId = errors?.length ? `${id}-error` : undefined
// 	return (
// 		<div className={className}>
// 			<Label htmlFor={id} {...labelProps} />
// 			<InputOTP
// 				pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
// 				maxLength={6}
// 				id={id}
// 				aria-invalid={errorId ? true : undefined}
// 				aria-describedby={errorId}
// 				{...inputProps}
// 			>
// 				<InputOTPGroup>
// 					<InputOTPSlot index={0} />
// 					<InputOTPSlot index={1} />
// 					<InputOTPSlot index={2} />
// 				</InputOTPGroup>
// 				<InputOTPSeparator />
// 				<InputOTPGroup>
// 					<InputOTPSlot index={3} />
// 					<InputOTPSlot index={4} />
// 					<InputOTPSlot index={5} />
// 				</InputOTPGroup>
// 			</InputOTP>
// 			<div className="min-h-[32px] px-4 pb-3 pt-1">
// 				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
// 			</div>
// 		</div>
// 	)
// }
